<template>
	<!-- container -->
	<div>
		<div class="titArea v">
			<div class="inner">
				<h2 class="txtL v3">입주사</h2>
				<a href="" class="btnBack" @click="goBuilding()">이전 페이지</a>
				<!-- <div class="rightArea">
					<a class="btn btnWhite ss" @click="goBuilding()">관리빌딩으로 이동</a>
				</div> -->
			</div>
		</div>
		<!-- contents -->
		<div class="contents">
			<!-- listTop -->
			<div class="listTop v2">
				<div class="right" v-if="auth">
					<a class="btnAddObject" @click="openTenant()">입주사 등록</a>
				</div>
			</div>
			<!--// listTop -->
			<!-- 입주사 List -->
			<div class="communityList v2">
				<ul>
					<li v-for="(tenant, index) in tenantList" :key="index">
						<a @click="auth ? openTenant(tenant.id) : null">
							<strong>{{ tenant.name }}</strong>
							<div class="viewInfo v2">
								<p class="date">
									<span>{{ tenant.managerName }}</span>
									<span>{{ tenant.contactNo | phoneFormat }}</span>
								</p>
								<p class="date">
									<span>{{ tenant.createDate | dateFormatYear }}</span>
									<span>{{ tenant.createDate | dateFormatMonth }}</span>
								</p>
							</div>
						</a>
					</li>
				</ul>
				<ul v-if="tenantList.length == 0">
					<div class="noData">
						<p>
							<img src="/resources/images/ico-no-data.svg" alt="" />
							<span>등록된 입주사가 없습니다.</span>
						</p>
					</div>
				</ul>
			</div>
			<!--// 입주사 List -->
			<!-- 입주사 등록 팝업 -->
			<div :class="tenantLayer" id="openTenant">
				<div class="inner" style="width:400px">
					<strong class="tit">입주사 {{ tenantData.id == null ? '등록' : '수정' }}</strong>
					<div class="cont">
						<div class="inputList">
							<dl>
								<dt>입주사명 <em>*</em></dt>
								<dd>
									<input
										type="text"
										title="입주사 입력"
										placeholder="입주사명을 입력해주세요."
										required="required"
										v-model.trim="tenantData.name"
										maxlength="30"
									/>
								</dd>
							</dl>
							<dl>
								<dt>관리자명</dt>
								<dd>
									<input
										type="text"
										title="관리자명 입력"
										placeholder="관리자명을 입력해주세요."
										required="required"
										v-model.trim="tenantData.managerName"
										maxlength="10"
									/>
								</dd>
							</dl>
							<dl>
								<dt>관리자 전화번호</dt>
								<dd>
									<input
										type="text"
										title="전화번호 입력"
										placeholder="전화번호는 '-'없이 입력해주세요."
										required="required"
										v-model.trim="tenantData.contactNo"
										maxlength="11"
									/>
								</dd>
							</dl>
						</div>
						<div class="btnArea">
							<button type="button" class="btn btnGray" @click="closeTenant">취소</button>
							<button type="button" class="btn btnGray" @click="onTenantRemove(tenantData.id)" v-if="tenantData.id">
								삭제
							</button>
							<button type="button" class="btn" :disabled="!tenantData.name" @click="onTenantSave">저장</button>
						</div>
					</div>
					<a @click="closeTenant">닫기</a>
				</div>
			</div>
			<!-- 입주사 등록 팝업 -->
		</div>
		<!--// contents -->
	</div>
	<!--// container -->
</template>
<script>
import {
	showSpinner,
	hideSpinner,
	numberComma,
	dateFormatYear,
	dateFormatMonth,
	phoneFormat,
	getAuth,
} from '@/utils/cmm.js';
import tenant from '@/api/tenant';
import building from '@/api/building';
export default {
	data() {
		return {
			orderBy: this.$route.params.orderBy == null ? 'createDate' : this.$route.params.orderBy,
			total: 0,
			tenantList: [],
			noDataStatus: true,
			noDataText: '',
			tenantLayer: 'layerPop',
			tenantData: {},
			auth: false,
		};
	},
	created() {},
	mounted() {
		this.onGetBuilding();
		this.onGet();
	},
	filters: {
		numberComma,
		dateFormatYear,
		dateFormatMonth,
		phoneFormat,
	},
	beforeDestroy() {},
	methods: {
		async onGet() {
			let params = {
				buildingId: this.$route.params.buildingId,
				orderBy: this.orderBy,
			};
			try {
				const res = await tenant.get(params);
				this.total = res.total;
				if (this.total > 0) {
					this.tenantList = res.data;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
			this.noDataStatus = true;
		},
		async openTenant(id) {
			// 모바일 스크롤 영역 - 바깥쪽 스크롤 영역 비활성화
			document.documentElement.style.overflow = 'hidden';
			try {
				showSpinner();
				this.tenantLayer = 'layerPop on';
				if (id != null) {
					this.tenantData = await tenant.getOne(id);
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '입주사 등록');
			} finally {
				hideSpinner();
			}
		},
		closeTenant() {
			this.tenantLayer = 'layerPop';
			// 모바일 스크롤 영역 - 바깥쪽 스크롤 영역 활성화
			document.documentElement.style.overflow = 'auto';
			this.tenantData = {};
		},
		async onTenantSave() {
			try {
				showSpinner();
				this.tenantData.buildingId = this.$route.params.buildingId;
				if (this.tenantData.id == null) {
					await tenant.create(this.tenantData);
				} else {
					await tenant.update(this.tenantData);
				}
				this.onGet();
				this.closeTenant();
			} catch (ex) {
				this.$alert(`입주사등록 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onTenantRemove(id) {
			if (!(await this.$confirm('입주사를 삭제하시겠습니까?', '삭제 안내'))) return;
			try {
				showSpinner();
				await tenant.remove(id);
				this.onGet();
				this.closeTenant();
			} catch (ex) {
				this.$alert(`입주사삭제 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		goBuilding() {
			this.$router.push({ name: 'buildingDetail', params: { id: this.$route.params.buildingId } });
		},
		async onGetBuilding() {
			try {
				let res = await building.getOne(this.$route.params.buildingId);
				let auth = getAuth();
				if (auth.id === res.owner) {
					this.auth = true;
				}
			} catch (ex) {
				this.$alert(`${ex.message}`);
			}
		},
	},
};
</script>

<style scoped></style>
